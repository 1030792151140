import { render, staticRenderFns } from "./coinRange.vue?vue&type=template&id=8a3d3a48&scoped=true"
import script from "./coinRange.vue?vue&type=script&lang=js"
export * from "./coinRange.vue?vue&type=script&lang=js"
import style0 from "./coinRange.vue?vue&type=style&index=0&id=8a3d3a48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a3d3a48",
  null
  
)

export default component.exports